<template>
  <div class="companyProfile">
    <div class="companyProfile-title">
      <h1>公司简介</h1>
    </div>
    <div class="companyProfile-content">
      <span>
       {{content}}
      </span>
      <img :src="imgSrc" class="companyProfile-img" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "",
  data() {
    return {
      companyInfo: [],
      content:'',
      imgSrc:''
    };
  },
  created() {
    this.$store.state.navIndex = "/companyProfile";
  },
  mounted(){
       axios
      .get("/api/image/list", {
        params: {
          category: "5",
        },
      })
      .then((res) => {
        this.imgSrc =
          "http://106.55.230.35" + res.data.content[0].url;
      });
    axios.get("/api/info/list").then((res) => {
      this.companyInfo = res.data.content;
      this.content = this.companyInfo[5].content;
    });
  }
};
</script>

<style scoped>
.companyProfile-title {
  text-align: center;
  border-bottom: 0.1px solid rgb(202, 202, 202);
  margin-bottom: 40px;
  padding: 0;
}
.companyProfile-content {
  text-indent: 2rem;
  font-size: 17px;
  margin: 0 auto;
  width: 70%;
}
.companyProfile-img {
  width: 100%;
  height: 400px;
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 20px;
  object-fit: cover;
}
</style>